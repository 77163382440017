class BISForm extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector('form');
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this));

    document.querySelectorAll('.klaviyo-bis-close').forEach((elem) => {
      elem.addEventListener('click', this.onClickHandler.bind(this));
    });
  }

  sendDataToKlaviyo(email, productId) {
    const url = 'https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=U8rCt6';
    const productString = '$shopify:::$default:::' + productId;
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2023-09-15',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: {
            channels: ['EMAIL'],
            profile: { data: { type: 'profile', attributes: { email: email } } }
          },
          relationships: {
            variant: { data: { type: 'catalog-variant', id: productString } }
          }
        }
      })
    };

    fetch(url, options)
      .then((res) => {
        if (res.ok) {
          this.querySelector('form').textContent = 'Thank You!';
        }
        res.json();
      })
      .then((json) => console.log(json))
      .catch((err) => console.log(err));
  }
  sendListToKlaviyo(email) {
    const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=U8rCt6';
    const options = {
      method: 'POST',
      headers: { revision: '2023-09-15', 'content-type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'Back in stock form',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: email
                }
              }
            }
          },
          relationships: { list: { data: { type: 'list', id: 'XWPEqh' } } }
        }
      })
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error('error:' + err));
  }
  onSubmitHandler(event) {
    event.preventDefault();
    const email = this.querySelector('#email').value;
    const productId = document.querySelector('#productInformationForm .product-variant-id').value;
    const subscribeToList = this.querySelector('#subscribe-to-list').checked;
    console.log(email, productId, subscribeToList);
    this.sendDataToKlaviyo(email, productId);
    if (subscribeToList) this.sendListToKlaviyo(email);
  }
  onClickHandler(event) {
    this.classList.add('hidden');
    this.classList.remove('flex');
  }
}

customElements.define('klaviyo-bis', BISForm);
